import { axios } from '@/utils/request'
import api from '@/api/index'

export function searchImportByPlateReport (parameter) {
  return axios({
    url: api.report.importByPlate.search,
    method: 'get',
    params: parameter
  })
}

export function detailImportByPlateReport (parameter) {
  return axios({
    url: api.report.importByPlate.detail,
    method: 'get',
    params: parameter
  })
}

export function exportImportByPlateReport (parameter) {
  return axios({
    url: api.report.export,
    method: 'post',
    data: parameter,
    responseType: 'blob'
  })
}
