export default [
  // {
  //   slots: { title: 'actionTitle' },
  //   dataIndex: 'operation',
  //   scopedSlots: { customRender: 'operation' },
  //   align: 'center',
  //   width: 50
  // },
  {
    title: 'STT',
    dataIndex: 'rowIndex',
    scopedSlots: { customRender: 'rowIndex' },
    align: 'center',
    width: 50
  },
  {
    title: 'Kho',
    dataIndex: 'warehouseName',
    scopedSlots: { customRender: 'warehouseName' },
    align: 'left',
    ellipsis: true,
    width: 500
  },
  {
    title: 'Biển số xe nhập hàng',
    dataIndex: 'plateNumber',
    scopedSlots: { customRender: 'plateNumber' },
    align: 'center',
    ellipsis: true,
    width: 300
  },
  {
    title: 'Thời gian',
    dataIndex: 'importAt',
    scopedSlots: { customRender: 'importAt' },
    align: 'center',
    ellipsis: true,
    width: 300
  }
]
